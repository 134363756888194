import React from 'react';

export default function TermsCondition() {
  return (
    <>
      <div className='container-fluid'>
        <h2 className='pt-4 heading'> TERMS & CONDITIONS </h2>
        <h5  className='text-start heading1' >Eligibility & Authority</h5>
        <p className='text-start webfont1 pt-2'>  
        This Site and the Services are available only to individuals and corporate entity (“Users”) who can form legally binding contracts under applicable law. By using this Site or the Services, you represent and warrant that you are at least eighteen (18) years of age, otherwise recognized as being able to form legally binding contracts under applicable law, or are not a person barred from purchasing or receiving the Services found under the laws of India or other applicable jurisdiction. If you are entering into this Agreement and availing the services which are provided under the link provided to you on behalf of, you represent and warrant that you have the legal authority to bind such individual or corporate entity to the terms and conditions contained herein, in which case the terms “you”, “your”, “User” or “customer” shall refer to such individual or corporate entity. If, after your electronic acceptance of this Agreement, in case finds that you do not have the legal authority to bind such individual or corporate entity, you will be personally responsible for the obligations contained in this Agreement, including, but not limited to, the payment obligations. If there is reasonable doubt about the authenticity of any such instruction, notice, document or communication, reserves the right (but undertakes no duty) to require additional authentication from you. You further agree to be bound by the terms of this Agreement for transactions entered into by you, anyone acting as your agent and anyone who uses your account or the Services, whether or not authorized by you.
        </p>

        <div>
          <h5 className='text-start heading1'>Your Account and Purchasing Service, Registration Obligations</h5>
          <p className='text-start webfont1 pt-2'> 
          If you use the Website, You shall be responsible for maintaining the confidentiality of your Display Name and Password and you shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access to your membership on the Website and refuse to provide You with access to the Website.
          </p>
          <p className='text-start webfont1 pt-2'>
          Your mobile phone number and/or e-mail address is treated as your primary identifier on the Website. It is your responsibility to ensure that your mobile phone number and your email address is up to date on the Website at all times. You agree to notify us promptly if your mobile phone number or e-mail address changes by updating the same on the Website through onetime password verification. You agree that FTB Innosphere shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your display name in cases where you have failed to update your revised mobile phone number and/or e-mail address on the Website.
          </p>
        </div>
        
        <div>
          <h5 className='text-start heading1'>General Rules of Conduct</h5>
          <h5 className='text-start webfont1 pt-2'>You acknowledge and agree that :</h5>
          <ul>
          <li className='text-start webfont1 pt-2'> Your use of this Site and the Services, including any content you submit, will comply with this terms and conditions, any applicable Services Agreement or policy that may apply to your Services and all applicable local, state, national and international laws, rules and regulations.</li>
          <li className='text-start webfont1 pt-2'> You will not collect or harvest (or permit anyone else to collect or harvest) any User Content (as defined below) or any non-public or personally identifiable information about another User or any other person or entity without their express prior written consent.</li>
          <li className='text-start webfont1 pt-2'>   You will not use this Site or the Services in a manner (as determined by in its sole and absolute discretion) that: 
            <ul>
              
              <li className='text-start webfont1 pt-2'> Is illegal, or promotes or encourages illegal activity;</li>
              <li className='text-start webfont1 pt-2'> Promotes, encourages or engages in child pornography or the exploitation of children;</li>
              <li className='text-start webfont1 pt-2'> Promotes, encourages or engages in terrorism, violence against people, animals, or property;</li>
              <li className='text-start webfont1 pt-2'> Infringes on the intellectual property rights of another User or any other person or entity;</li>
              <li className='text-start webfont1 pt-2'> Violates the privacy or publicity rights of another User or any other person or entity, or breaches any duty of confidentiality that you owe to another User or any other person or entity;</li>
              <li className='text-start webfont1 pt-2'> Interferes with the operation of this Site or the Services found at this Site;</li>
              <li className='text-start webfont1 pt-2'> Contains or installs any viruses, worms, bugs, Trojan horses or other code, files or programs designed to, or capable of, disrupting, damaging or limiting the functionality of any software or hardware;</li>
              <li className='text-start webfont1 pt-2'> Contains false or deceptive language, or unsubstantiated or comparative claims, regarding or Services</li>
            </ul>
          </li>
          <li className='text-start webfont1 pt-2'> You will not copy or distribute in any medium any part of this Site or the Services, except where expressly authorized.</li>
          <li className='text-start webfont1 pt-2'>You will not modify or alter any part of this Site or the Services found at this Site or any of its related technologies.</li>
          <li className='text-start webfont1 pt-2'> You will not access Content (as defined below) or User Content through any technology or means other than through this Site itself, or as may designate.</li>
          <li className='text-start webfont1 pt-2'> You agree to back-up all of your User Content so that you can access and use it when needed. FTB Innosphere does not warrant that it backs-up any Account or User Content, and you agree to accept as a risk the loss of any and all of your User Content.</li>
          <li className='text-start webfont1 pt-2'> You will not re-sell or provide the Services for a commercial purpose, including any of related technologies, without FTB Innosphere’s express prior written consent.</li>
          <li className='text-start webfont1 pt-2'> You will not to circumvent, disable or otherwise interfere with the security related features of this Site or the Services found at this Site (including without limitation those features that prevent or restrict use or copying of any Content or User Content) or enforce limitations on the use of this Site or the Services found at this Site, the ’s Content or the User Content therein.</li>
          <li className='text-start webfont1 pt-2'> You agree to provide government-issued photo identification and/or government-issued business identification as required for verification of identity when requested.</li>
          <li className='text-start webfont1 pt-2'> You are aware that we may from time-to-time call you about your account, and that, for the purposes of any and all such call(s), we may be subject to call recording and hereby consent to the same, subject to any applicable laws and our restrictions and obligations thereunder, including, where permissible, to record the entirety of such calls regardless of whether asks you on any particular call for consent to record such call. You further acknowledge and agree that, to the extent permitted by applicable law, any such recording(s) may be submitted as evidence in any legal proceeding in which is a party. Further, by providing your telephone or mobile number, you consent to receive marketing telephone calls from or on behalf of that may be initiated by an automatic telephone dialling system and/or use an artificial or prerecorded voice. You understand that providing consent is not a condition of purchasing any good or service from. Similarly, by providing your mobile number, you consent to receive marketing text messages from or on behalf of that may be sent by an automatic telephone dialling system. You understand that providing consent is not a condition of purchasing any good or service from. Message and data rates may apply.</li>
          </ul>
        </div>
        <div>
          <h5 className='text-start heading1 pt-2'> User Content</h5>
          <p className='text-start webfont1 pt-2'>Some of the features of this Site or the Services, including those Services that are hosted with , FTB Innosphere may allow Users to view, post, publish, share, store, or manage (a) ideas, opinions, recommendations, or advice via forum posts, content submitted in connection with a contest, product reviews or recommendations, or photos to be incorporated into a social media event or activity (“User Submissions”), or (b) literary, artistic, musical, or other content, including but not limited to photos and videos (together with User Submissions, “User Content”). All content submitted through your Account is considering User Content. By posting or publishing User Content to this Site or to or via the Services, you represent and warrant to that (i) you have all necessary rights to distribute User Content via this Site or via the Services, either because you are the author of the User Content and have the right to distribute the same, or because you have the appropriate distribution rights, licenses, consents, and/or permissions to use, in writing, from the copyright or other owner of the User Content, and (ii) the User Content does not violate the rights of any third party. You shall be solely responsible for any and all of your User Content or User Content that is submitted through your Account, and the consequences of, and requirements for, distributing it. </p>
        </div>
        <div>
          <h5 className='text-start heading1 pt-2'> User Submissions</h5>
          <p className='text-start webfont1 pt-2'> You acknowledge and agree that your User Submissions are entirely a confidential or obligate to treat your User Submissions as confidential or secret, FTB Innosphere shall not disclose any of the user submission to anyone outside the Company nor use for any purpose other than services to be provided by FTB Innosphere. This confidential or proprietary information shall disclose to the employees, representatives, directors or to anyone inside the Company on a “need-to-know” basis only. And no compensation is due to you or to anyone else for any intentional or unintentional use of your User Submissions, and that may be working on the same or similar content, it may already know of such content from other sources, it may simply wish to develop this (or similar) content on its own, or it may have taken / will take some other action. </p>
        </div>
        <div>
          <h5 className='text-start heading1 pt-2'> Trademark, Copyright and Restriction </h5>
          <p className='text-start webfont1 pt-2'> Platform is controlled and operated by FTB Innosphere. All material on Platform, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights. Material on FTB Innosphere is solely for personal as well as commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other FTB Innosphere or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use for which You receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause. It is expressly clarified that You will retain ownership and shall solely be responsible for any content that You provide or upload when using any Service, including any text, data, information, images, photographs, music, sound, video or any other material which you may upload, transmit or store when making use of Our various Service. However, We reserve the right to use/reproduce any content uploaded by You and You agree to grant royalty free, irrevocably, unconditionally, perpetually and worldwide right to Us to use the content for reasonable business purpose. </p>
          <p className='text-start webfont1 pt-2'> FTB Innosphere also grants each User of this Site a non-exclusive license to access User Content (with the exception of User Content that you designate “private” or “password protected”) through this Site, and to use, reproduce, distribute, prepare derivative works of, combine with other works, display, and perform your User Content as permitted through the functionality of this Site and under this Agreement. Under which FTB Innosphere has no access towards the password of the user account. The above licenses granted by you in your User Content terminate within a commercially reasonable time after you remove or delete your User Content from this Site. You understand and agree, however, that may retain (but not distribute, display, or perform) server copies of your User Content that have been removed or deleted. The above licenses granted by you in your User Content are perpetual and irrevocable. Notwithstanding anything to the contrary contained herein, FTB Innosphere will ensure confidentiality within our knowledge and capacity, and, provide appropriate security measures available off the shelf in the market to ensure security. FTB Innosphere reserves the right to use client content, after taking written consent/permission from the client for any such content, which includes testimonials. If you have a website or other content hosted by you shall retain all of your ownership or licensed rights in User Content.</p>
        </div>
        <div>
          <h5 className='text-start heading1 pt-2'> User Conduct and Rules on the Platform</h5>
          <p className='text-start webfont1 pt-2'>You agree, undertake, and confirm that Your use of the Platform shall be strictly governed by the following binding principles:</p>
          <ul>
            <li className='text-start webfont1 pt-2'>You shall not host, display, upload, modify, publish, transmit, update, or share any information which: 
              <ul>
                <li className='text-start webfont1 pt-2'>belongs to another person and to which You do not have any right to</li>
                <li className='text-start webfont1 pt-2'>is grossly harmful, harassing, blasphemous, defamatory, obscene,pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever</li>
                <li className='text-start webfont1 pt-2'>is misleading in any way</li>
                <li className='text-start webfont1 pt-2'>involves the transmission of “junk mail”, “chain letters”, or unsolicited mass mailing or “spamming”</li>
                <li className='text-start webfont1 pt-2'>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous</li>
                <li className='text-start webfont1 pt-2'>infringes upon or violates any third party’s rights including, but not limited to, intellectual property rights, rights of privacy (including without limitationunauthorized disclosure of a person’s name, email address, physical address or phone number) or rights of publicity</li>
                <li className='text-start webfont1 pt-2'>contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page)</li>
                <li className='text-start webfont1 pt-2'>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses</li>
                <li className='text-start webfont1 pt-2'>contains video, photographs, or images of another person (with a minor or an adult)</li>
                <li className='text-start webfont1 pt-2'>tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users</li>
                <li className='text-start webfont1 pt-2'>interferes with another USER’s use and enjoyment of the Platform or any other individual’s User and enjoyment of similar services</li>
                <li className='text-start webfont1 pt-2'>infringes any patent, trademark, copyright, or other proprietary rights or third party’s trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products</li>
                <li className='text-start webfont1 pt-2'>violates any law for the time being in force</li>
                <li className='text-start webfont1 pt-2'>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation</li>
                <li className='text-start webfont1 pt-2'>shall not be false, inaccurate or misleading</li>
                <li className='text-start webfont1 pt-2'>shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider (“ISPs”) or other suppliers</li>
              </ul>
            </li>
            <li className='text-start webfont1 pt-2'> A User may be considered fraudulent or loss to business due to fraudulent activity if any of the following scenarios are met:
            <ul>
              <li className='text-start webfont1 pt-2'>Users doesn’t reply to the payment verification mail sent by FTB Innosphere</li>
              <li className='text-start webfont1 pt-2'>Users fails to produce adequate documents during the payment details verification</li>
              <li className='text-start webfont1 pt-2'>Misuse of another Users’ phone/email</li>
              <li className='text-start webfont1 pt-2'>Users uses invalid address, email and phone no.</li>
              <li className='text-start webfont1 pt-2'>Overuse of a voucher code</li>
              <li className='text-start webfont1 pt-2'>Use of a special voucher not tagged to the email ID used.</li>
              <li className='text-start webfont1 pt-2'>User uploads the wrong product/ document.</li>
              <li className='text-start webfont1 pt-2'>Users refuses to pay for an order</li>
              <li className='text-start webfont1 pt-2'>Miscellaneous activities conducted with the sole intention to cause loss to business/revenue to FTB Innosphere</li>
              <li className='text-start webfont1 pt-2'>Repeated request for monetary compensation for fake/used order</li>
            </ul>
            </li>
            <li className='text-start webfont1 pt-2'>You shall not use the Platform for any unlawful and fraudulent purposes, which may cause annoyance and inconvenience and abuses any policy and rules of the company and interrupt or causes to interrupt, damages the use by other Users of FTB Innosphere.</li>
            <li className='text-start webfont1 pt-2'> You shall not use any false e-mail address, impersonates any person or entity, or otherwise misleads FTB Innosphere by sharing multiple address and phone numbers or transacting with malafide intentions.</li>
            <li className='text-start webfont1 pt-2'> You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password “mining” or any other illegitimate means.</li>
            <li className='text-start webfont1 pt-2'> You may not pretend that You are, or that You represent, someone else, or impersonate any other individual or entity</li>
          </ul>
        </div>
        <div>
          <h5 className="text-start heading1 pt-2">Fees and Payments</h5>
          <p className='text-start webfont1 pt-2'> You agree that your Payment Method may be charged by our entity with our local payment service provider, and subject to the provisions of our Privacy Policy. </p>
          <p className='text-start webfont1 pt-2'> Payment Due at Time of Order; Non-Refundable </p>
          <p className='text-start webfont1 pt-2'> You agree to pay all amounts due for Services at the time you order them. All amounts are non-refundable unless otherwise noted in the Refund Policy. </p>
          <h5 className='text-start heading1 pt-2'>Price Changes  </h5>
          <p className='text-start webfont1 pt-2'> FTB Innosphere reserves the right to change its prices and fees at any time, and such changes shall be posted online at this Site and effective immediately without need for further notice to you. If you have purchased or obtained Services for a period of months or years, changes in prices and fees shall be effective when the Services in question come up for renewal as further described below. </p>
          <h5 className='text-start heading1 pt-2'>Payment Types  </h5>
          <p className='text-start webfont1 pt-2'> Except as prohibited in any product-specific agreement, you may pay for Services by using any of the following “Payment Methods”: (i) Net Banking, (ii) Valid Credit card (iii) Valid Debit card (iv) Unified Payment Interface (UPI), (v) Wallets, each a “Payment Method”. The “Express Checkout” feature automatically places an order for the applicable Service and charges the primary Payment Method on file for your Account. Confirmation of that order will be sent to the email address on file for your Account. Your Payment Method on file must be kept valid if you have any active Services in your Account. In addition, you agree that the location for the processing of your payments may change for any reason, including the type of Payment Method chosen, the currency selected, or changes or updates made to your Payment Method. </p>
          <h5 className='text-start heading1 pt-2'> Refunds Issued </h5>
          <p className='text-start webfont1 pt-2'> You agree that where refunds are issued to your Payment Method’s issuance of a refund receipt is only confirmation that has submitted your refund to the Payment Method charged at the time of the original sale, and that has no control over when the refund will be applied towards your Payment Method’s available balance. In case of Monthly Retainership or retainers midway refunds are not applicable. You further acknowledge and agree that the payment provider and/or individual issuing bank associated with your Payment Method establish and regulate the time frames for posting your refund, and that such refund posting time frames shall be as specified during payment gateway to a full billing cycle, or longer. In the event a refund is issued to your Payment Method and the payment provider, payment processor or individual issuing bank associated with your Payment Method imposes any limitations on refunds, including but not limited to, limitations as to the timing of the refund or the number of refunds allowed, then , in its sole and absolute discretion, reserves the right to issue the refund either (i) in the form of an in-store credit; (ii) via issuance of a check, which will be sent to the mailing address on file for your Account; (iii) same account used for original payment or (iv) in some jurisdictions, as a bank transfer, when the payment processor cannot refund back to the Payment Method. FTB Innosphere also has the right to offer an in-store credit for customers seeking refunds, even if there are no limitations on refunds imposed by the Payment Method. </p>
          <h5 className='text-start heading1 pt-2'> Auto-Renewal Terms </h5>
          <p className='text-start webfont1 pt-2'> Other than as required by applicable law, does not retain hard copies or electronic versions of mandate, standing order or standing instruction forms and/or any signed consents relating to your usage of our automatic renewal services, and we are therefore unable to provide any such document upon request. You may view or change your automatic renewal settings at any time by logging into your account. </p>
          <h5 className='text-start heading1 pt-2'> E-Platform for Communication: </h5>
          <p className='text-start webfont1 pt-2'> You agree, understand and acknowledge that FTB Innosphere is an online platform that enables you to provide services listed on the Platform at the price indicated therein at any time. You further agree and acknowledge that FTB Innosphere is only a facilitator and is not and cannot be a party to or control in any manner any transactions on FTB Innosphere. </p>
          <h5 className='text-start heading1 pt-2'> Indemnity: </h5>
          <p className='text-start webfont1 pt-2'> You shall indemnify and hold harmless FTB Innosphere, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party </p>
          <h5 className='text-start heading1 pt-2'> Limitation of Liability: </h5>
          <p className='text-start webfont1 pt-2'> In no event shall FTB Innosphere be liable for any indirect, punitive, incidental, special, consequential damages or any other damages resulting from: </p>
          <ul>
            <li className='text-start webfont1 pt-2'>the use or the inability to use the Services or Products </li>
            <li className='text-start webfont1 pt-2'> unauthorized access to or alteration of the user’s transmissions or data </li>
            <li className='text-start webfont1 pt-2'>breach of condition, representations or warranties by the manufacturer of the Products </li>
            <li className='text-start webfont1 pt-2'>any other matter relating to the services including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Platform or Service. FTB Innosphere shall not be held responsible for non-availability of the FTB Innosphere during periodic maintenance operations or any unplanned suspension of access to the FTB Innosphere. The User understands and agrees that any material and/or data downloaded at FTB Innosphere is done entirely at Users own discretion and risk and they will be solely responsible for any damage to their mobile or loss of data that results from the download of such material and/or data. To the maximum extend that is permissible under law, FTB Innosphere’s liability shall be limited to an amount equal to the Products purchased value bought by You. FTB Innosphere shall not be liable for any dispute or disagreement between Users. </li>
          </ul>
          <h5 className='text-start heading1 pt-2'> Jurisdictional Issues/Sale in India Only: </h5>
          <p className='text-start webfont1 pt-2'>Unless otherwise specified, the material on the Platform is presented for the purpose of sale or providing services in India and abroad. FTB Innosphere is not responsible for supply of products/refund for the products ordered from other locations/Countries other than India, compliance with local laws, if and to the extent local laws are applicable. </p>
          <h5 className='text-start heading1 pt-2'> Dispute Resolution and Governing Law: </h5>
          <p className='text-start webfont1 pt-2'> We shall be bound to submit all disputes and differences howsoever arising out of or in connection with this to mediation. In the event of failure to resolve the differences/dispute, or if any party disagrees with the decision, the same shall be referred to the Arbitration. These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts, tribunals, applicable authorities at Pune, Maharashtra. The place of jurisdiction shall be exclusively in Pune. </p>


        </div>

      </div>

    </>
  )
}

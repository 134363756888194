import React from 'react';
import '../css/bg.css';
import image from '../images/contact.jpg';

export default function Contact() {
  return (
    <>
    <div className="image-container" >
        <img src={image} alt='conatct' style={{width:'100%'}}/>
        <div className='text-overlay'>
            <h3 className='text-center' style={{fontSize:'1.5vw'}}>Connect with us... </h3>
            <h5 className='text-center' style={{fontSize:'1.5vw'}}> We are here to help you out </h5>
            <h5 className='text-center' style={{fontSize:'1.5vw'}}> Fill the 'Contact Us' and submit your queries </h5>
        </div>
      </div>
    
        <div className='pt-1'>
            <iframe  frameBorder="0" style={{height:'95vh',width:"100%",border:'none',display:'-ms-grid'}} title='contact' src='https://forms.zohopublic.in/simca/form/ContactUs/formperma/3A9vfh0qlXFEQ-ogA6s423McPXuTUjU-5pvXmgE7BEs'></iframe>
        </div>                           
    </>
  )
}
